import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Blank from "../components/Layout/Blank";
import Seo from "../components/seo/Seo";

require("../styles/css/openGraph.css");
const OpenGraph = ({ data: { settings } }) => {
  return (
    <Blank>
      <Seo title="Open Graph" />
      <Helmet bodyAttributes={{ class: "open-graph" }} />
      <div className="flex items-center justify-center w-full h-screen">
        <div className="bg-white w-80">
          <figure
            className="w-full bg-center bg-cover aspect bg-gray"
            style={{
              backgroundImage: `url(${settings.openGraph.image.asset.url})`,
            }}
          ></figure>
          <footer className="p-3 space-y-3 leading-none normal-case">
            <span className="mb-4 text-xs uppercase opacity-25">
              https://lookbooks.store
            </span>
            {settings.openGraph.title ? (
              <h3>{settings.openGraph.title}</h3>
            ) : (
              <h3>Site Title</h3>
            )}

            <div className="text-sm leading-snug opacity-50">
              {settings.openGraph.description}
            </div>
          </footer>
        </div>
      </div>
    </Blank>
  );
};

export default OpenGraph;

export const query = graphql`
  {
    settings: sanitySettings {
      openGraph {
        title
        image {
          asset {
            url
          }
        }
        description
      }
      id
    }
  }
`;
